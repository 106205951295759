#header {
  height: 70px;
  padding: 0 20px 0 30px;
  font-family: 'Lexend' !important;

  &.transperant {
    background: transparent !important;
  }
}

#logo {
  cursor: pointer;
}

#navbar {
  color: #343536;

  a {
    font-family: 'Lexend' !important;
  }

  .active {
    color: #534AD8;
  }


  input[type="checkbox"] {
    display: none;
  }

  .mobile-navbar {
    position: relative;
    top: -15px;

    .wrapper {
      display: none;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;

    &:hover {
      .dropdown-content {
        display: block;
      }
    }

    img {
      margin-left: 8.5px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #FFFFFF;
      width: 130px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      z-index: 1;
      margin-left: 30px;

      a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #F2F1FF;
          border-radius: 4px;
        }
      }

      &.active {
        background-color: #F2F1FF;
        border-radius: 4px;
      }

    }
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
}


.schedule-a-demo-btn {
  padding-left: 30px;

  .schedule-demo {
    border: none;
    color: #FFFFFF;
    width: 141px;
    height: 32px;
    background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
    border-radius: 4px;
  }
}

// media queries for RWD

@media only screen and (max-width: 991px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #navbar {
    .mobile-navbar {
      .wrapper {
        display: block;

        .mobile-dropdown-arrow {
          width: 16px;
          height: 16px;
          position: relative;
          top: 20px;
          left: 60px;
          transition: transform .9s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .dropdown-submenu {
          border: 3px solid #F2F1FF;
          border-radius: 5px;

          &:hover {
            background-color: #F2F1FF;
          }

          &.active {
            background-color: #F2F1FF;
          }
        }
      }
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    /*left: -100%;*/
    right: -100%;
    height: 100%;
    width: 100%;
    background: #fff;
    transition: all 0.6s ease-in-out;
  }

  #active:checked~.wrapper {
    right: 0;
  }

  .menu-btn {
    position: absolute;
    z-index: 2;
    right: 0;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .menu-btn span,
  .menu-btn:before,
  .menu-btn:after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 30%;
    width: 40%;
    border-bottom: 2px solid #000;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .menu-btn:before {
    transform: translateY(-8px);
  }

  .menu-btn:after {
    transform: translateY(8px);
  }

  .close {
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: background .6s;
  }

  /* closing animation */
  #active:checked+.menu-btn span {
    transform: scaleX(0);
  }

  #active:checked+.menu-btn:before {
    transform: rotate(45deg);
    border-color: #000;
  }

  #active:checked+.menu-btn:after {
    transform: rotate(-45deg);
    border-color: #000;
  }

  .wrapper ul {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
  }

  .wrapper ul li {
    height: 10%;
    width: 200px;
    position: relative;
  }

  .wrapper ul li a {
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    padding: 5px 30px;
    border-radius: 50px;
    position: absolute;
    line-height: 50px;
    margin: 5px 30px;
    opacity: 0;
    transition: all 0.3s ease;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #343536;
    font-size: 20px;
    left: 50%;
  }

  .navbar a,
  .navbar a:focus {
    font-size: 20px;
    padding: 5px 30px;
  }

  #active:checked~.wrapper ul li a {
    opacity: 1;
  }

  .wrapper ul li a {
    transition: opacity 1.2s, transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateX(100px);
  }

  #active:checked~.wrapper ul li a {
    transform: none;
    transition-timing-function: ease, cubic-bezier(.1, 1.3, .3, 1);
    transition-delay: .6s;
    transform: translateX(-100px);
  }

}