.solutions {
  text-align: center;

  .solutions-header-container {
    display: flex;
    align-items: center;
    padding: 40px 0;
    background-image: url("../../public/assets/img/all/solutions-header-bg.svg");
    background-size: cover;
    margin-top: 70px;
    height: 350px;

    .solutions-header {
      display: flex;
      align-items: center;

      .left-section {
        width: 70%;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 42px;
        letter-spacing: 0.0025em;
        color: #343536;
        text-align: left;
        margin: 0;
        padding: 0;

        .text-purple-gradient {
          font-weight: 600;
        }
      }

      .left-section {
        justify-content: flex-start;

        .home-button-container {
          justify-content: flex-start;
          margin-top: 15px;
        }
      }
    }
  }

  .solutions-section-one-container {
    background-image: url("../../public/assets/img/all/solutions-section-1-bg.svg");
    background-size: cover;
    color: #ffffff !important;
    height: 480px;
    display: flex;
    align-items: center;

    .solutions-section-one {
      margin-top: 80px;
      height: auto;

      .title {
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        background: linear-gradient(90.04deg,
            #644bc4 2.35%,
            #007bfa 71.29%,
            #714cb5 95.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      p {
        margin-top: 15px;
      }
    }
  }

  .solutions-section-two-container {
    background-image: url("../../public/assets/img/all/solutions-section-2-bg.svg");
    background-size: cover;
    color: #ffffff !important;

    .process-section {
      position: relative;
      top: 50px;
    }

    h2 {
      font-weight: 500;
      font-size: 34px;
      line-height: 42px;
      text-align: center;
      letter-spacing: 0.0025em;

      color: #ffffff;
    }

    @media screen and (max-width:576px) {
      .image-ver-hor {
        display: none;
      }
    }

    .image-ver-hor {
      .process {
        margin-top: 50px;
        width: 80%;
      }
    }

    @media screen and (min-width:576px) {
      .image-ver-hor1 {
        display: none;
      }
    }

    .image-ver-hor1 {
      .process {
        margin-top: 50px;
        width: 80%;
        margin-left: -105px;
      }
    }


    p {
      width: 70%;
      margin: 0 auto 0;
      color: #ffffff;
      position: relative;
      top: 50px;
    }

    img.onboarding {
      width: 75%;
      position: relative;
      bottom: -150px;
    }
  }

  .solutions-section-three-container {
    .solutions-section-three {
      display: flex;
      align-items: center;
      text-align: left;
      margin: 100px 0;
      padding-top: 120px;

      h3 {
        font-weight: 400;
        font-size: 34px !important;
        line-height: 42px;
        letter-spacing: 0.0025em;
        color: #343536;
      }

      .connectWith {
        text-align: left;
        width: 35%;
        margin-right: 75px;

        p {
          margin-top: 30px;
        }
      }
    }
  }

  .solutions-section-four-container {
    background: linear-gradient(180deg,
        #f7f7ff 0%,
        #f7f7ff 67.71%,
        rgba(247, 247, 255, 0) 100%);
    padding: 80px 0;

    p {
      width: 70%;
      margin: 0 auto 0;
      position: relative;
      padding: 15px 0 30px;
    }
  }

  .solutions-section-five-container {
    padding: 40px 0 0;
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;

    .container {

      p {
        width: 70%;
        margin: 0 auto 0;
        position: relative;
        padding: 15px 0 30px;
      }
    }

    img {
      position: relative;
      bottom: 100px;
    }
  }

  .solutions-section-six-container {
    background: #423ab4;
    padding: 80px 0;

    h3 {
      color: #ffffff;
    }

    p {
      width: 70%;
      margin: 0 auto 0;
      position: relative;
      padding: 15px 0 30px;
      color: #ffffff;
    }
  }

  .solutions-section-seven-container {
    background: linear-gradient(180deg,
        rgba(247, 247, 255, 0) 0%,
        #f7f7ff 18.23%,
        #f7f7ff 50.52%,
        rgba(247, 247, 255, 0) 100%);
    padding: 80px 0;

    p {
      width: 70%;
      margin: 0 auto 0;
      position: relative;
      padding: 30px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .solutions {
    .solutions-header-container {
      background-image: url("../../public/assets/img/all/solutions-header-bg-sm.svg");
      height: auto;
      width: 100%;

      .solutions-header {
        flex-direction: column !important;
        width: 100%;

        img {
          padding: 30px;
        }

        .left-section {
          width: 80%;
        }

      }
    }

    .solutions-section-one-container {
      background-image: url("../../public/assets/img/all/solutions-section-1-sm-bg.svg");
      padding-bottom: 0;
      margin-bottom: 0;

      .solutions-section-one {
        //   height: auto;
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100%;

        // height: 100%;
        padding-top: 70px;

        .title {
          font-size: 28px !important;
          font-weight: 600 !important;
          line-height: 35px !important;
        }

        p {
          font-size: 18px !important;
        }
      }
    }

    .solutions-section-two-container {
      height: 1120px;

      .process-section {
        margin: 1%;

        h2 {
          size: 34px !important;
          font-weight: 400 !important;
          line-height: 42.5px !important;
        }

        p {
          width: 80%;
          font-family: Lexend;
          font-size: 16px !important;
          font-weight: 400 !important;
          line-height: 28px !important;
          letter-spacing: 0em !important;
          text-align: center !important;
        }

        .onboarding {
          width: 100%;
        }
      }
    }

    .solutions-section-three-container {

      .solutions-section-three {
        padding: 50px !important;
        margin: 0 !important;

        h3 {
          //styleName: Headers/ H4 - Regular - 34|Auto;
          width: 120%;
          margin-left: -20px;
          font-family: Lexend;
          font-size: 34px !important;
          font-weight: 400;
          line-height: 43px;
          letter-spacing: 0.0025em;
          text-align: center;
        }

        flex-direction: column;

        p {
          width: 12rem;
        }

        img {
          width: 100%;
          margin-top: 10%;
        }

        .connectWith {
          // margin: 0px !important;
          // padding: 0px !important;
          margin-left: 30% !important;
          width: 100% !important;
        }

        p {
          margin-top: 20px;
          width: 100%;
        }
      }
    }

    .solutions-section-four-container {

      .solutions-section-four {
        padding-bottom: 0;

        img {
          width: 368px;
        }

        h3 {
          //styleName: Headers/ H4 - Regular - 34|Auto;
          font-family: Lexend;
          font-size: 34px;
          font-weight: 400;
          line-height: 43px;
          letter-spacing: 0.0025em;
          text-align: center;
        }

        p {
          //styleName: Body/Body - Regular - 14|24;
          font-family: Lexend;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.01em;
          text-align: center;
          width: 85%;
        }

        //   padding: 0 !important;
      }
    }

    .solutions-section-five-container {
      
      .solutions-section-five {
        flex-direction: column;

        h3 {
          font-size: 34px;
          font-weight: 400;
          line-height: 43px;
          letter-spacing: 0.0025em;
          text-align: center;
        }
      }

      img {
        bottom: 20px;
      }

    }

    .solutions-section-six-container {

      // height: 649px;
      .solutions-section-six {

        h3 {
          font-size: 34px;
          font-weight: 400;
          line-height: 43px;
          letter-spacing: 0.0025em;
          text-align: center;
        }

        img {
          width: 90%;
        }
      }
    }

    .solutions-section-seven-container {

      // height: 714px;
      .solutions-section-seven {
        img {
          width: 90%;
        }

        h3 {
          font-size: 34px;
        }
      }
    }
  }
}

@media screen and (max-width:756px) {
  .image-illuminar-ver-hor {
    display: none;
  }

}

@media screen and (min-width:756px) {
  .image-illuminar-ver-hor1 {
    display: none;
  }

}