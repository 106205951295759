.all-sections {}

#home {
    text-align: center;
    background-image: url('../../public/assets/img/all/canvas-background.svg');
    min-height: calc(100vh - 70px);
    background-size: cover;
    padding-top: 110px;

    h2 {
        font-weight: 400;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.0025em;
    }

    .home-header-label {
        font-size: 60px;
        line-height: 75px;
        text-align: center;
        letter-spacing: -0.015em;
        background: linear-gradient(90.04deg, #644BC4 2.35%, #007BFA 71.29%, #714CB5 95.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .caption-text {
        width: 50%;
        text-align: center;
        margin: auto;
        margin-bottom: 15px;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #343536;
        }
    }

    .home-button-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.01em;

        .learn-more {
            border: none;
            color: #FFFFFF;
            width: 103px;
            height: 32px;
            background: linear-gradient(45.36deg, #01BC47 -4.75%, #21F075 116%);
            border-radius: 4px;
            margin-right: 8px;
        }

        .schedule-demo {
            border: none;
            color: #FFFFFF;
            width: 141px;
            height: 32px;
            background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
            border-radius: 4px;
        }
    }

    .feature-container {
        display: flex;
        justify-content: center;
        margin: 70px 0 50px;

        .feature {
            margin: 0 16px;
            height: 400px;
            width: 340px;
            text-align: left;

            a {
                text-decoration: none;
            }

            .services-tile {
                height: 100%;
                background-image: url('../../public/assets/img/all/home-tile-2-bg.svg');
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 26px;
            }

            .solutions-tile {
                height: 100%;
                background-image: url('../../public/assets/img/all/home-tile-1-bg.svg');
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 26px;

                img {
                    width: 85%;
                    margin: 0 auto;
                }
            }

            .partners-tile {
                height: 100%;
                background-image: url('../../public/assets/img/all/home-tile-3-bg.svg');
                background-size: cover;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 26px;
            }

            .text {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                color: #1E2225;
            }

            img {
                width: 100%;
                position: relative;
                bottom: 25px;
            }
        }
    }
}

// media queries for RWD

@media only screen and (min-width: 1025px) {}

@media only screen and (min-width: 768px) and (max-width: 1025px) {}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    #home {
        padding-left: 10px;
        padding-right: 10px;
        background-image: url('../../public/assets/img/all/canvas-background-verticle.svg');

        .feature-container {
            flex-direction: column;

            .feature {
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    #home {
        padding-left: 10px;
        padding-right: 10px;
        background-image: url('../../public/assets/img/all/canvas-background-verticle.svg');

        h2 {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.0025em;
        }

        .home-header-label {
            font-size: 45px;
            line-height: 55px;
        }

        .caption-text {
            width: 85%;
            margin: 17px auto 21px;

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.01em;
            }
        }

        .feature-container {
            flex-direction: column;

            .feature {
                margin: auto;
            }
        }
    }
}