.social-media-links-container {
  display: flex;
  padding-left: 25px;

  a {
    padding: 0 3px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    margin-right: 5px;

    &:focus {
      padding: 0 3px !important;
    }
  }
}

// media queries for RWD

@media only screen and (max-width: 576px) {
  
}