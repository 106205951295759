.contact-us-section {
    font-family: 'Lexend';
    color: #475258;
    margin-top: 70px;
    min-height: calc(100vh - 140px);

    .header-color {
        color: rgba(66, 58, 180, 1);
    }

    .contact-us-header {
        background-image: url('../../public/assets/img/all/contact-us/contact-us-bg.svg');
        background-size: cover;
        height: 290px;
        display: flex;
        align-items: center;
        text-align: left;

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 34px;
            line-height: 42px;
            letter-spacing: 0.0025em;
            background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }

        .para {
            width: 40%;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #475258;
            margin-top: 15px;
        }
    }

    .contact-body {
        padding-bottom: 100Px;
        text-align: left;
        width: 40%;
        margin-top: 35px;

        .general-inquiry {
            //styleName: Body/Body - Regular - 14|24;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.01em;
        }

        .email {
            padding-top: 40px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
        }

        .email-address {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: rgba(71, 82, 88, 1);
        }

        .follow-us {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            padding-top: 40px;
        }

        .social-media {
            height: 20px;
            display: flex;
            display: inline-flex;

            .handle {
                width: 25px;
                padding-right: 5px;
            }
        }

    }

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .contact-form {
        width: 50%;
        border-radius: 10px;
        position: relative;
        bottom: 170px;
        background: #FFFFFF;
        text-align: left;
        //styleName: Body/Body - Regular - 14|24;
        font-family: 'Lexend';
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 28px;
        margin: 0;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #475258;
        }

        .input-item {
            padding-right: 5px;
        }

        input {
            display: block;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #E1E5EA;
            border-radius: 4px;
            height: 45px;
            margin-bottom: 20px;
            padding-left: 6px;
        }

        .first-name {
            margin-right: 25px;
        }

        .first-name,
        .last-name {
            width: 50%;
        }

        textarea {
            display: block;
            width: 100%;
            height: 150px;
            border-radius: 4px;
            background: #FFFFFF;
            border: 1px solid #E1E5EA;
            border-radius: 4px;
            resize: none;
            padding-left: 6px;
        }

        .email-input {
            width: 100%;
        }

        .name-row {
            display: flex;
        }

        .disclaimer {
            text-align: left;
            padding-top: 10px;
            font-size: 13px;
        }

        .agreement {
            font-size: 13px;
            display: flex;
            padding-top: 15px;

            .consent-box {
                display: flex;

                .checkbox {
                    width: 20px;
                    height: 20px;
                    padding-right: 20px;
                    padding-top: 10px;
                }

                .agreement-text {
                    padding-left: 12px;
                    font-size: 13px;
                    line-height: 16px;
                    color: #475258;
                }
            }

            .submit-button {
                border: none;
                color: #FFFFFF;
                width: 141px;
                height: 32px;
                width: 122px;
                padding: 5px 12px 5px 12px;
                border-radius: 4px;
                background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                border-radius: 4px;
            }
        }
    }
}

// media queries for RWD

@media only screen and (min-width: 1025px) {}

@media only screen and (min-height: 768px) and (max-width: 1025px) {}

@media only screen and (min-height: 576px) and (max-height: 768px) {}

@media only screen and (max-width: 576px) {
    .contact-us-section {

        .contact-us-header {
            background-image: url('../../public/assets/img/all/contact-us/contact-us-bg-sm.svg');
            height: 550px;
            align-items: flex-start;
            padding: 50px 35px;

            .para {
                width: 90%;
            }
        }

        .contact-body {
            width: 90%;
            padding-bottom: -10px;
            text-align: center;
            margin: auto;

            .email {
                text-align: center;
            }
        }

        .content {
            flex-direction: column-reverse;
            position: relative;
            bottom: 150px;
        }

        .contact-form {
            width: 96%;
            bottom: 100px;
            margin: auto;

            .name-row {
                flex-direction: column;

                .first-name,
                .last-name {
                    width: 100%;
                }
            }

            .disclaimer {
                margin-top: 20px;
            }

            .agreement {
                flex-direction: column;
                align-items: end;

                .consent-box {
                    display: flex;
                }

                .submit-button {
                    margin-top: 20px;
                }
            }
        }
    }

}