.animation-div {
    margin: 7px 0px 17px 0px;
    overflow: hidden;

    .pop-out-animation {
        animation: 1.6s anim-lineUp ease-out;
        animation-iteration-count:9;
    
        @keyframes anim-lineUp {
            0% {
                opacity: 0;
                transform: translate3d(0px,100%,0px);
            }
    
            15% {
                opacity: 1;
                transform: translate3d(0px,0%,0px);
            }
    
            50% {
                opacity: 1;
                transform: translate3d(0px,0%,0px);
            }
    
            100% {
                opacity: 1;
                transform: translate3d(0px,0%,0px);
            }
        }
    }

    .home-header-label {
        font-size: 60px;
        line-height: 75px;
        text-align: center;
        letter-spacing: -0.015em;
        background: linear-gradient(90.04deg, #644BC4 2.35%, #007BFA 71.29%, #714CB5 95.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
}

.typed-out {
    overflow: hidden;
    border-right: .15em solid #714CB5;
    white-space: nowrap;
    //   font-size: 1.6rem;
    width: 0;
    // animation: typing 1s steps(10, end) forwards;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}