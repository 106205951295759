.footer {
    text-align: center;
    background-image: url('../../public/assets/img/all/footer-background.svg');
    background-size: cover;
    height: 100%;
    padding-top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;

    .footer-text {
        padding: 90px 0;

        .footer-logo {
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                width: 70%;
                text-align: center;
                margin: 0 auto 30px;
            }

            .home-button-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.01em;

                .learn-more {
                    border: none;
                    color: #FFFFFF;
                    width: 103px;
                    height: 32px;
                    background: linear-gradient(45.36deg, #01BC47 -4.75%, #21F075 116%);
                    border-radius: 4px;
                    margin-right: 8px;
                }

                .schedule-demo {
                    border: none;
                    color: #FFFFFF;
                    width: 141px;
                    height: 32px;
                    background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                    border-radius: 4px;
                }
            }
        }
    }

    .copyrights {
        margin-top: 0;
        text-align: left;
        display: flex;
        justify-content: space-between;
        font-family: 'Lexend' !important;

        .text-section {
            display: flex;
        }

        p {
            font-family: 'Lexend' !important;
            font-size: 12px;
            line-height: 20px;
            color: #343536;
            margin-right: 20px;

            a {
                text-decoration: none;
                color: #343536;
                cursor: pointer;
            }
        }

        .social-media-links-container {
            margin-right: 40px;
        }
    }
}


@media screen and (max-width: 768px) {
    .footer {
        background-image: url('../../public/assets/img/all/footer-background-sm.svg');
    }

    .social-media-links-container {
        display: none;
    }
}