.services {
    text-align: center;

    .services-header-container {
        display: flex;
        align-items: center;
        padding: 40px 0;
        background-image: url('../../public/assets/img/all/solutions-header-bg.svg');
        background-size: cover;
        margin-top: 70px;

        @media screen and (max-width: 767px) {
            background-image: url('../../public/assets/img/all/solutions-header-bg-sm.svg');
        }

        .services-header {
            display: flex;
            align-items: center;

            @media screen and (max-width: 767px) {
                flex-direction: column;
            }

            .left-section {
                width: 70%;
                justify-content: flex-start;

                .home-button-container {
                    justify-content: flex-start;
                    margin-top: 15px;
                }

                .header-text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #343536;
                    text-align: left;

                    .text-purple-gradient {
                        font-weight: 600;
                    }
                }

                p {
                    color: #475258;
                    text-align: left;
                }
            }

            img {
                margin-bottom: -40px;
            }
        }
    }

    .services-section-one-container {
        padding-bottom: 70px;

        .services-section-one-aws {
            display: flex;
            justify-content: center;
            padding-top: 70px;

            .sections {
                display: flex;
                padding: 0 20px;

                img {
                    height: fit-content;
                    padding: 5px 20px 0 0;
                }

                .text-content {
                    max-width: 340px;
                    text-align: left;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #343536;
                }
            }
        }
    }

    .services-section-two-container {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFF5E9 100%);
        padding: 30px 0 20px;

        .services-section-two {
            padding: 0px 0;

            .button-container {
                .btn {
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    margin: 10px;
                    padding: 11px 25px;
                    text-transform: capitalize;

                    &.active {
                        color: #FFFFFF;
                        background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                .button-container {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .services-tabs-container {
        padding: 20px 0 10px;

        .services-tabs {
            padding: 0px 0;

            .tab {
                .header-text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.01em;
                    color: #343536;
                    width: 80%;
                    margin: auto;
                }

                &.show {
                    display: block !important;
                }

                &.hide {
                    display: none !important;
                }

                &.tab-one {
                    .header-text {
                        width: 73%;
                    }

                    .content {
                        .main-img {
                            margin-top: -50px;
                        }
                    }
                }

                &.tab-two {
                    .header-text {
                        width: 60%;
                    }

                    .content {
                        img {
                            margin-top: -70px;
                        }
                    }
                }

                &.tab-three {
                    .content {
                        img {
                            margin-top: 5px;
                        }
                    }
                }

                &.tab-four {
                    .header-text {
                        width: 60%;
                    }

                    .content {
                        img {
                            margin-top: 50px;
                        }
                    }
                }
            }

            .section-below-tabs {

                .solutions-tab-one-section-two-container {
                    background: linear-gradient(180deg, #F7F7FF 0%, #F7F7FF 67.71%, rgba(247, 247, 255, 0) 100%);

                    .solutions-tab-one-section-two {
                        display: flex;
                        justify-content: center;
                        padding: 20px 0;

                        .left-section {
                            width: 37%;
                            text-align: left;
                            margin: 40px 20px;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                            }
                        }

                        .right-section {
                            width: 35%;
                            text-align: left;
                            margin: 40px 20px;

                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                margin-bottom: 40px;
                            }
                        }
                    }
                }

                .solutions-tab-one-section-three-container {
                    .solutions-tab-one-section-three {
                        display: flex;
                        justify-content: center;
                        padding: 50px 0;

                        .left-section {
                            width: 35%;
                            text-align: left;
                            margin: 40px 20px;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                                width: 55%;
                                margin-bottom: 28px;
                            }

                            p {
                                width: 80%;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                color: #343536;
                            }
                        }

                        .right-section {
                            width: 50%;
                        }
                    }
                }

                .solutions-tab-one-section-four-container {
                    .solutions-tab-one-section-four {
                        display: flex;
                        justify-content: center;
                        padding: 50px 0;

                        .left-section {
                            width: 50%;
                        }

                        .right-section {
                            width: 35%;
                            text-align: left;
                            margin: 40px 20px;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                                width: 90%;
                                margin-bottom: 28px;
                            }

                            p {
                                width: 80%;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                color: #343536;
                            }
                        }
                    }
                }

                .solutions-tab-one-section-five-container {
                    .solutions-tab-one-section-five {
                        display: flex;
                        justify-content: center;
                        padding: 50px 0;

                        .left-section {
                            width: 35%;
                            text-align: left;
                            margin: 40px 20px;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                                width: 82%;
                                margin-bottom: 28px;
                            }

                            p {
                                width: 80%;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                color: #343536;
                            }
                        }

                        .right-section {
                            width: 50%;
                        }
                    }
                }

                .solutions-tab-one-section-six-container {
                    margin-bottom: 70px;

                    .solutions-tab-one-section-six {
                        display: flex;
                        justify-content: center;
                        padding: 50px 0;

                        .left-section {
                            width: 50%;
                        }

                        .right-section {
                            width: 35%;
                            text-align: left;
                            margin: 40px 20px;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                                width: 90%;
                                margin-bottom: 28px;
                            }

                            p {
                                width: 80%;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                color: #343536;
                            }
                        }
                    }

                    .button-container {
                        .collaboration {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: -0.01em;
                            color: #FFFFFF;
                            align-items: center;
                            padding: 5px 12px;
                            background: linear-gradient(45.31deg, #01BC47 -4.75%, #09C250 67.65%, #00EB5F 110.13%);
                            border-radius: 4px;
                            border: none;
                        }
                    }
                }

                .solutions-tab-one-section-seven-container {
                    background: linear-gradient(180deg, #F7F7FF 0%, #F7F7FF 67.71%, rgba(247, 247, 255, 0) 100%);

                    .solutions-tab-one-section-seven {
                        padding: 20px 0 50px;

                        .left-section {
                            width: 70%;
                            text-align: center;
                            margin: 40px auto;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                                width: 92%;
                                margin: auto;
                                margin-bottom: 28px;
                            }

                            p {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                color: #343536;
                                width: 90%;
                                margin: auto;
                            }
                        }

                        .right-section {
                            width: 70%;
                            margin: auto;
                        }
                    }
                }

                .solutions-tab-one-section-eight-container {
                    background-image: url('../../public/assets/img/services-1/rozie-rocket-cloud.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    padding-bottom: 120px;
                    background-position: bottom;

                    .solutions-tab-one-section-eight {
                        display: flex;
                        justify-content: center;
                        padding: 100px 0 0;

                        .left-section {
                            width: 25%;
                        }

                        .right-section {
                            width: 44%;
                            text-align: left;
                            margin: 40px 20px;

                            h3 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 34px;
                                line-height: 42px;
                                letter-spacing: 0.0025em;
                                color: #1E2225;
                                margin-bottom: 28px;
                            }

                            p {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: -0.01em;
                                color: #343536;
                            }
                        }
                    }

                    .button-container {
                        .collaboration {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: -0.01em;
                            color: #FFFFFF;
                            align-items: center;
                            padding: 5px 12px;
                            background: linear-gradient(45.31deg, #01BC47 -4.75%, #09C250 67.65%, #00EB5F 110.13%);
                            border-radius: 4px;
                            border: none;
                            margin: 10px;
                        }

                        .aws-marketplace {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: -0.01em;
                            color: #FFFFFF;
                            align-items: center;
                            padding: 5px 12px;
                            background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                            border-radius: 4px;
                            border: none;
                            margin: 10px;
                        }
                    }
                }
            }

        }

        .read-more {
            display: flex;
            height: 18px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #8595A6;
            flex: none;
            order: 1;
            flex-grow: 0;
            cursor: pointer;
            position: relative;
            right: 5px;

            .more-arrow {
                align-items: center;
                vertical-align: middle;
            }

            .text {
                margin-left: 9px;
            }
        }
    }
}

.mobile-art {
    display: none;
}

.web-art {
    display: block;
    margin: 40px auto 20px 10px;
    width: 50%;
}

@media screen and (max-width: 767px) {
    .mobile-art {
        display: block;
        padding-top: 34px;
    }

    .web-art {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .services {
        .services-section-one-container {
            .services-section-one {
                flex-direction: column;
                padding-top: 50px;

                .sections {
                    margin: 20px 0;
                }
            }
        }

        .services-tabs-container {
            .services-tabs {
                .tab.tab-one {
                    .content {
                        .main-img {
                            width: 100%;
                        }
                    }
                }

                .tab.tab-two {
                    .header-text {
                        width: 80%;
                    }

                    .content {
                        img {
                            width: 100%;
                            margin-top: 0;
                        }
                    }
                }

                .tab.tab-three {
                    .content {
                        img {
                            width: 100%;
                        }
                    }
                }

                .tab.tab-four {
                    .header-text {
                        width: 80%;
                    }

                    .content {
                        img {
                            width: 100%;
                            margin-top: 35px;
                        }
                    }
                }

                .section-below-tabs {

                    .solutions-tab-one-section-two-container {
                        .solutions-tab-one-section-two {
                            flex-direction: column;

                            .left-section {
                                width: 90%;
                                text-align: center;
                            }

                            .right-section {
                                width: 90%;
                                text-align: center;
                            }
                        }
                    }

                    .solutions-tab-one-section-three-container {
                        .solutions-tab-one-section-three {
                            flex-direction: column;

                            .left-section {
                                width: 90%;
                                text-align: center;

                                h3,
                                p {
                                    width: 100%;
                                }
                            }

                            .right-section {
                                width: 100%;

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .solutions-tab-one-section-four-container {
                        .solutions-tab-one-section-four {
                            flex-direction: column-reverse;

                            .left-section {
                                width: 100%;

                                img {
                                    width: 100%;
                                }

                            }

                            .right-section {
                                width: 90%;
                                text-align: center;

                                h3,
                                p {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .solutions-tab-one-section-five-container {
                        .solutions-tab-one-section-five {
                            flex-direction: column;

                            .left-section {
                                width: 90%;
                                text-align: center;

                                h3,
                                p {
                                    width: 100%;
                                }
                            }

                            .right-section {
                                width: 100%;

                                img {
                                    width: 100%
                                }
                            }
                        }
                    }

                    .solutions-tab-one-section-six-container {
                        .solutions-tab-one-section-six {
                            flex-direction: column-reverse;

                            .left-section {
                                width: 100%;

                                img {
                                    width: 100%;
                                }
                            }

                            .right-section {
                                width: 90%;
                                text-align: center;

                                h3,
                                p {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .solutions-tab-one-section-seven-container {
                        .solutions-tab-one-section-seven {
                            .left-section {
                                width: 90%;
                                text-align: center;

                                h3,
                                p {
                                    width: 100%;
                                }
                            }

                            .right-section {
                                width: 100%;

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .solutions-tab-one-section-eight-container {
                        background: none;

                        .solutions-tab-one-section-eight {
                            flex-direction: column-reverse;

                            .left-section {
                                width: 100%;
                                margin-bottom: 30px;

                                img {
                                    width: 50%;
                                    margin: 0 auto;
                                }
                            }

                            .right-section {
                                width: 90%;
                                text-align: center;
                            }

                            .button-container {
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }
    }
}