.services {
    text-align: center;

    .services-header-container {
        display: flex;
        align-items: center;
        padding: 40px 0;
        background-image: url('../../public/assets/img/all/solutions-header-bg.svg');
        background-size: cover;
        margin-top: 70px;

        @media screen and (max-width:576px) {
            background-image: url('../../public/assets/img/all/solutions-header-bg-sm.svg');
        }

        .services-header {
            display: flex;
            align-items: center;

            @media screen and (max-width:576px) {
                flex-direction: column;
            }

            .left-section {
                width: 70%;
                justify-content: flex-start;

                .home-button-container {
                    justify-content: flex-start;
                }

                .header-text {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #343536;
                    text-align: left;
                }

                p {
                    color: #475258;
                    text-align: left;
                }
            }

            img {
                margin-bottom: -40px;
            }

            .mobile-art {
                display: none;
            }

            .web-art {
                display: block;
            }

            @media screen and (max-width: 576px) {
                .mobile-art {
                    display: block;
                    padding-top: 34px;
                }

                .web-art {
                    display: none;
                }
            }
        }
    }

    .services-section-one-container {
        padding-bottom: 75px;

        .services-section-one {
            display: block;
            margin-left: auto;
            margin-right: auto;
            padding-top: 75px;

            .header {
                font-weight: 400;
                font-size: 34px;
                line-height: 42px;
                letter-spacing: 0.0025em;
                color: #1E2225;
                display: flex;
                justify-content: center;

                div {
                    margin: 0 10px;

                    .title {
                        text-align: center;
                        background: linear-gradient(90.04deg, #534AD8 100%, #834C9D 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }

                p {
                    margin: 40px 0px;

                }

                button {
                    margin: 10px 0px;
                }
            }

            @media screen and (max-width: 576px) {
                .header {
                    flex-direction: column;
                    font-weight: 300;
                    font-size: 30px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                }
            }

            p {
                width: 50%;
                margin: 32px auto;
            }

            @media screen and (max-width: 576px) {
                p {
                    font-weight: 300;
                    font-size: 18px;
                    width: 80%;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    text-align: center;
                    margin: 12px auto;
                }
            }

            .schedule-kt-session-btn {
                background: linear-gradient(45.36deg, #01BC47 -4.75%, #21F075 116%);
                border-radius: 4px;
                border: none;
                padding: 4px 10px;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #FFFFFF;
            }
        }
    }

    .services-section-two-container {
        background: linear-gradient(180deg, #FFF9EF 0%, #F7F7FF 51.56%, rgba(247, 247, 255, 0) 100%);
        padding: 50px 0;

        .services-section-two {
            padding: 0px 0;

            .header {
                font-weight: 400;
                font-size: 34px;
                line-height: 42px;
                letter-spacing: 0.0025em;
                color: #1E2225;
                padding-bottom: 20px;
            }

            .content {
                .item {
                    width: 70%;
                    margin: 32px auto;
                    display: flex;
                    background: #FFFFFF;
                    border-image-slice: 1;
                    padding: 30px 50px;
                    justify-content: space-between;
                    text-align: left;

                    &.purple-box {
                        border: 10px solid #743ad5;
                        border-width: 0 0 4px 0;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 4px 100px 100px 100px;

                        .left-section {
                            background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    &.orange-box {
                        border: 10px solid #EF6C00;
                        border-width: 0 0 4px 0;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 4px 100px 100px 100px;
                        position: relative;
                        left: 48px;

                        .left-section {
                            background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    &.blue-box {
                        border: 10px solid #0058D2;
                        border-width: 0 0 4px 0;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 4px 100px 100px 100px;
                        position: relative;
                        right: 48px;

                        .left-section {
                            background: linear-gradient(45.38deg, #0058D2 -23.51%, #35A7FF 126.5%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    .left-section {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30%;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                    }

                    .right-section {
                        width: 66%;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        color: #343536;
                    }
                }

            }

            @media screen and (max-width:576px) {
                .content {
                    .item {
                        width: 100%;
                        margin: 32px auto;
                        display: flex;
                        flex-direction: column;
                        background: #FFFFFF;
                        border-image-slice: 1;
                        padding: 30px 50px;
                        justify-content: space-between;
                        text-align: left;

                        &.purple-box {
                            border: 10px solid #743ad5;
                            border-width: 0 0 4px 0;
                            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 4px 100px 100px 100px;

                            .left-section {
                                background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }
                        }

                        &.orange-box {
                            border: 10px solid #EF6C00;
                            border-width: 0 0 4px 0;
                            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 4px 100px 100px 100px;
                            position: relative;
                            left: 0px;

                            .left-section {
                                background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }
                        }

                        &.blue-box {
                            border: 10px solid #0058D2;
                            border-width: 0 0 4px 0;
                            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 4px 100px 100px 100px;
                            position: relative;
                            right: 0px;

                            .left-section {
                                background: linear-gradient(45.38deg, #0058D2 -23.51%, #35A7FF 126.5%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }
                        }

                        .left-section {
                            width: 90%;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 23px;
                        }

                        .right-section {
                            margin-top: 20px;
                            width: 90%;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: -0.01em;
                            color: #343536;
                        }
                    }

                }
            }
        }

        @media screen and (max-width: 576px) {
            .services-section-two {
                padding: 0px 0;

                .content {
                    margin: 10px;
                }
            }

            .header {
                font-weight: 400;
                font-size: 30px;
                line-height: 42px;
                letter-spacing: 0.0025em;
                color: #1E2225;
                padding-bottom: 20px;
            }



        }
    }

    .services-section-three-container {
        padding: 50px 0;

        .services-section-three {
            padding: 0px 0;

            .header {
                font-weight: 400;
                font-size: 34px;
                line-height: 42px;
                letter-spacing: 0.0025em;
                color: #1E2225;
                padding-bottom: 20px;
            }

            .header-description {
                width: 60%;
                margin: 0 auto;
                font-weight: 400;
            }

            .content {
                background: #F7F7FF;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 50px;
                border: 10px solid #743ad5;
                border-width: 0 0 4px 0;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                width: 80%;
                margin: 40px auto;

                .item {
                    padding: 20px 15px 20px 20px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
                    border-radius: 10px;
                    width: 45%;
                    margin: 20px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    .numbering {
                        width: 36px;
                        height: 36px;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        color: #FFFFFF;
                        background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 100px;
                        line-height: 36px;
                        position: absolute;
                        top: -16px;
                        left: -16px;
                    }

                    .text {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: #343536;
                        padding-bottom: 50px;
                        width: 75%;
                        text-align: left;

                        .text-purple-gradient {
                            font-weight: 600;
                        }
                    }

                    .artwork {
                        height: 70px;
                        width: 70px;
                        position: absolute;
                        right: 20px;
                        bottom: 10px;
                    }
                }


            }

            @media screen and (max-width: 576px) {
                .content {
                    padding: 30px;
                    width: 100%;

                    .item {
                        width: 100%;
                        margin: 5px;
                        margin-bottom: 40px;

                        .text {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }

        }

        @media screen and (max-width: 576px) {
            .services-section-three {

                .header {
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #1E2225;
                    padding-bottom: 20px;
                }

                .header-description {
                    font-weight: 300;
                    font-size: 18px;
                    width: 80%;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    text-align: center;
                    margin: 12px auto;
                }
            }

        }
    }

    @media screen and (max-width:576px) {
        .services-section-four-container {
            display: none;
        }
    }

    .services-section-four-container {

        .services-section-four {
            padding: 60px 0;

            .header {
                font-weight: 400;
                font-size: 34px;
                line-height: 42px;
                letter-spacing: 0.0025em;
                color: #1E2225;
                padding-bottom: 20px;
            }

            .header-description {
                width: 60%;
                margin: 0 auto;
                font-weight: 400;
            }

            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 80%;
                margin: 40px auto;

                .item {
                    padding: 20px 15px 20px 20px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
                    border-radius: 10px;
                    width: 45%;
                    margin: 32px 20px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    &:first-child {
                        margin-left: calc(50% - 220px);
                    }

                    &:last-child {
                        margin-left: calc(50% - 220px);
                    }

                    .text {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: #343536;
                        padding-bottom: 50px;
                        width: 80%;
                        text-align: left;

                        .text-purple {
                            font-weight: 600;
                        }
                    }

                    .artwork {
                        height: 70px;
                        width: 70px;
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                    }

                    &.purple-bg {
                        background: #EAE9FF;
                        border-bottom: 4px solid #534AD8;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            left: calc(100% + 50px);
                            top: 45%;
                        }

                        .arrow1 {
                            position: fixed;
                            display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }

                    &.green-bg {
                        background: #EFFCF4;
                        border-bottom: 4px solid #49BE69;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(45.31deg, #01BC47 -4.75%, #09C250 67.65%, #00EB5F 110.13%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            right: calc(50% - 0px);
                            bottom: calc(100% + 50px);
                        }

                        .arrow1 {
                            position: fixed;
                            display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }

                    &.orange-bg {
                        background: #FFF5EA;
                        border-bottom: 4px solid #FA9620;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            top: calc(100% + 50px);
                            left: 55%;
                        }

                        .arrow1 {
                            position: fixed;
                            display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }

                    &.blue-bg {
                        background: #E3EEFF;
                        border-bottom: 4px solid #0058D2;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(45.38deg, #0058D2 -23.51%, #35A7FF 126.5%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            right: calc(100% + 50px);
                            bottom: 45%;
                        }
                    }
                }
            }

            @media screen and (max-width: 576px) {
                .content {
                    display: flex;
                    flex-direction: column;
                    //         flex-wrap: wrap;
                    //         justify-content: space-between;
                    //         width: 80%;
                    //         margin: 40px auto;

                    .item {
                        padding: 20px 15px 20px 20px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
                        border-radius: 10px;
                        width: 100%;
                        margin: 50px 0px 30px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-left: 0;
                        }

                        .text {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px;
                            color: #343536;
                            padding-bottom: 50px;
                            width: 80%;
                            text-align: left;

                            .text-purple {
                                font-weight: 600;
                            }
                        }

                        .artwork {
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            right: 10px;
                            bottom: 10px;
                        }

                        &.purple-bg {
                            background: #EAE9FF;
                            border-bottom: 4px solid #534AD8;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                background-image: url("../../public/assets/img/all/downArrowRight.svg");
                                display: none;
                                position: absolute;
                                left: calc(100% + 50px);
                                top: 45%;
                            }

                            .arrow1 {
                                padding-top: 10px;
                                position: absolute;
                                left: calc(30px);
                                top: 100%;
                            }
                        }

                        &.green-bg {
                            background: #EFFCF4;
                            border-bottom: 4px solid #49BE69;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(45.31deg, #01BC47 -4.75%, #09C250 67.65%, #00EB5F 110.13%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                display: none;
                                right: calc(50% - 0px);
                                bottom: calc(100% + 50px);
                            }

                            .arrow1 {
                                padding-top: 10px;
                                position: absolute;
                                left: calc(200px);
                                top: 100%;
                            }
                        }

                        &.orange-bg {
                            background: #FFF5EA;
                            border-bottom: 4px solid #FA9620;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                display: none;
                                top: calc(100% + 50px);
                                left: 55%;
                            }

                            .arrow1 {
                                padding-top: 10px;
                                position: absolute;
                                left: calc(30px);
                                top: 100%;
                            }
                        }

                        &.blue-bg {
                            background: #E3EEFF;
                            border-bottom: 4px solid #0058D2;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(45.38deg, #0058D2 -23.51%, #35A7FF 126.5%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                display: none;
                                right: calc(100% + 50px);
                                bottom: 45%;
                            }
                        }
                    }
                }
            }

        }

        @media screen and (max-width: 576px) {
            .services-section-four {

                .header {
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #1E2225;
                    padding-bottom: 20px;
                }

                .header-description {
                    font-weight: 300;
                    font-size: 18px;
                    width: 80%;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    text-align: center;
                    margin: 12px auto;
                }
            }

        }
    }

    @media screen and (min-width:576px) {
        .services-section-four-container1 {
            display: none;
        }
    }

    .services-section-four-container1 {

        .services-section-four {
            padding: 60px 0;

            .header {
                font-weight: 400;
                font-size: 34px;
                line-height: 42px;
                letter-spacing: 0.0025em;
                color: #1E2225;
                padding-bottom: 20px;
            }

            .header-description {
                width: 60%;
                margin: 0 auto;
                font-weight: 400;
            }

            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 80%;
                margin: 40px auto;

                .item {
                    padding: 20px 15px 20px 20px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
                    border-radius: 10px;
                    width: 45%;
                    margin: 32px 20px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    &:first-child {
                        margin-left: calc(50% - 220px);
                    }

                    &:last-child {
                        margin-left: calc(50% - 220px);
                    }

                    .text {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: #343536;
                        padding-bottom: 50px;
                        width: 80%;
                        text-align: left;

                        .text-purple {
                            font-weight: 600;
                        }
                    }

                    .artwork {
                        height: 70px;
                        width: 70px;
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                    }

                    &.purple-bg {
                        background: #EAE9FF;
                        border-bottom: 4px solid #534AD8;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            left: calc(100% + 50px);
                            top: 45%;
                        }

                        .arrow1 {
                            position: fixed;
                            // display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }

                    &.green-bg {
                        background: #EFFCF4;
                        border-bottom: 4px solid #49BE69;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(45.31deg, #01BC47 -4.75%, #09C250 67.65%, #00EB5F 110.13%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            right: calc(50% - 0px);
                            bottom: calc(100% + 50px);
                        }

                        .arrow1 {
                            position: fixed;
                            // display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }

                    &.orange-bg {
                        background: #FFF5EA;
                        border-bottom: 4px solid #FA9620;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            top: calc(100% + 50px);
                            left: 55%;
                        }

                        .arrow1 {
                            position: fixed;
                            // display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }

                    &.blue-bg {
                        background: #E3EEFF;
                        border-bottom: 4px solid #0058D2;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                        border-radius: 10px;

                        .title {
                            background: linear-gradient(45.38deg, #0058D2 -23.51%, #35A7FF 126.5%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }

                        .arrow {
                            position: absolute;
                            right: calc(100% + 50px);
                            bottom: 45%;
                        }

                        .arrow1 {
                            position: fixed;
                            // display: none;
                            left: calc(100% + 50px);
                            top: 45%;
                        }
                    }
                }
            }

            @media screen and (max-width: 576px) {
                .content {
                    display: flex;
                    flex-direction: column;

                    .item {
                        padding: 20px 15px 20px 20px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
                        border-radius: 10px;
                        width: 100%;
                        margin: 50px 0px 30px;
                        position: relative;
                        display: flex;
                        justify-content: space-between;

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-left: 0;
                        }

                        .text {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px;
                            color: #343536;
                            padding-bottom: 50px;
                            width: 80%;
                            text-align: left;

                            .text-purple {
                                font-weight: 600;
                            }
                        }

                        .artwork {
                            height: 70px;
                            width: 70px;
                            position: absolute;
                            right: 10px;
                            bottom: 10px;
                        }

                        &.purple-bg {
                            background: #EAE9FF;
                            border-bottom: 4px solid #534AD8;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                background-image: url("../../public/assets/img/all/downArrowRight.svg");
                                display: none;
                                position: absolute;
                                left: calc(100% + 50px);
                                top: 45%;
                            }

                            .arrow1 {
                                padding-top: 10px;
                                position: absolute;
                                left: calc(30px);
                                top: 100%;
                            }
                        }

                        &.green-bg {
                            background: #EFFCF4;
                            border-bottom: 4px solid #49BE69;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(45.31deg, #01BC47 -4.75%, #09C250 67.65%, #00EB5F 110.13%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                display: none;
                                right: calc(50% - 0px);
                                bottom: calc(100% + 50px);
                            }
                        }

                        &.orange-bg {
                            background: #FFF5EA;
                            border-bottom: 4px solid #FA9620;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(180deg, #EF6C00 14.58%, #FF9800 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                display: none;
                                top: calc(100% + 50px);
                                left: 55%;
                            }

                            .arrow1 {
                                padding-top: 10px;
                                position: absolute;
                                left: calc(200px);
                                top: 100%;
                            }
                        }

                        &.blue-bg {
                            background: #E3EEFF;
                            border-bottom: 4px solid #0058D2;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                            border-radius: 10px;

                            .title {
                                background: linear-gradient(45.38deg, #0058D2 -23.51%, #35A7FF 126.5%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                            }

                            .arrow {
                                display: none;
                                right: calc(100% + 50px);
                                bottom: 45%;
                            }

                            .arrow1 {
                                padding-top: 10px;
                                position: absolute;
                                left: calc(30px);
                                top: 100%;
                            }
                        }
                    }
                }
            }

        }

        @media screen and (max-width: 576px) {
            .services-section-four {

                .header {
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #1E2225;
                    padding-bottom: 20px;
                }

                .header-description {
                    font-weight: 300;
                    font-size: 18px;
                    width: 80%;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    text-align: center;
                    margin: 12px auto;
                }
            }

        }
    }

}