#partners {
    margin-top: 70px;
    text-align: center;



    .partners-header-container {
        background-image: url('../../public/assets/img/all/partners-header-bg.svg');
        background-size: cover;

        .partners-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;

            .left-section {
                h3 {
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #475258;
                }

                .home-button-container {
                    justify-content: flex-start;
                }

            }

            img {
                width: 350px;
            }
        }


    }


    .partners-section-one-container {
        margin-top: 50px;

        .partners-section-one {
            h3 {
                font-size: 34px !important;
                line-height: 42px;
                text-align: center;
                letter-spacing: 0.0025em;
                color: #343536;
            }

            .partners-section-one-header {

                .web {
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 60px;
                    text-align: center;
                    background: linear-gradient(90.04deg, #644BC4 2.35%, #007BFA 71.29%, #714CB5 95.56%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    display: block;
                }

                .mobile {
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 60px;
                    text-align: center;
                    background: linear-gradient(90.04deg, #644BC4 2.35%, #007BFA 71.29%, #714CB5 95.56%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    display: none;
                }

                img {
                    padding: 0 20px;
                }
            }

            .strategic-partners {
                margin-top: 50px;

                span {
                    font-size: 18px;
                    line-height: 42.5px;
                    text-align: center;
                }

                .process {
                    margin-top: 10px;
                    margin-left: -20px;
                }

                .process1 {
                    margin-top: 10px;
                    margin-left: 40px;
                }

                @media screen and (max-width:768px) {
                    .process {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .process1 {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            .power-your-solution {
                margin-top: 100px;
                margin-bottom: 50px;
                font-size: 34px;


                h3 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #1E2225;
                }

                p {
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 0.0025em;
                    color: #343536;
                    margin-top: 14px;
                }
            }

            .partners-section-1-artwork {
                margin-bottom: 50px;
            }
        }
    }

    .partners-section-two-container {
        background: linear-gradient(180deg, #F7F7FF 0%, #F7F7FF 67.71%, rgba(247, 247, 255, 0) 100%);
        padding: 65px 0;

        .partners-section-two {
            display: flex;
            align-items: center;
            text-align: left;
            justify-content: center;

            .left-section {
                width: 20%;
                margin-right: 60px;

                h3 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #1E2225;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: #343536;
                    margin-top: 30px;
                }
            }
        }
    }

    .partners-section-three-container {
        padding: 50px 0;

        .partners-section-three {
            display: flex;
            flex-direction: column;
            align-items: center;

            .left-section {
                width: 65%;

                h3 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 34px;
                    line-height: 42px;
                    letter-spacing: 0.0025em;
                    color: #1E2225;
                }

                p {
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 0.0025em;
                    color: #343536;
                    margin-top: 14px;
                }
            }


            img {
                margin: 50px 0;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    #partners {
        .partners-header-container {
            background-image: url('../../public/assets/img/all/partners-header-bg-sm.svg');

            .partners-header {
                flex-direction: column;
                height: 590px;

                .left-section {
                    margin: 30px;
                    padding: 16px;

                    h3 {
                        width: 267px;
                        height: 43px;
                        font-family: 'Lexend';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 42px;
                        letter-spacing: 0.0025em;
                        background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }

                    p {

                        font-family: 'Lexend';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px !important;
                        line-height: 18px !important;
                        color: #475258 !important;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;

                    }

                    .home-button-container {
                        justify-content: flex-start;
                    }
                }

                img {
                    height: 350px;
                    width: -webkit-fill-available !important;
                }
            }
        }

        .partners-section-one-container {

            .partners-section-one {
                .partners-section-one-header {

                    .web {
                        display: none;
                    }

                    .mobile {
                        display: block;
                    }

                    img {
                        padding: 0 40px;
                    }
                }

                .strategic-partners {
                    margin-top: 100px;

                    span {
                        font-size: 18px;
                        line-height: 42px;
                        text-align: center;
                    }

                    img {
                        margin: 20px auto 0;
                        display: block;
                    }
                }

                .power-your-solution {
                    font-size: 34px;
                    width: 80%;
                    margin: 100px auto 50px;
                }

                .partners-section-1-artwork {
                    margin-bottom: 50px;
                    flex-direction: column;
                    width: -webkit-fill-available;
                }
            }
        }


        .partners-section-two-container {
            display: flex;
            flex-direction: column;

            .partners-section-two {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: left;
                justify-content: space-between;

                .left-section {
                    width: 70%;
                    margin: auto;
                    text-align: center;
                }

                img {
                    width: 100%;
                }
            }
        }

        .partners-section-three-container {
            .partners-section-three {
                .left-section {
                    width: 70%;
                }

                img {
                    margin: 40px 0;
                    width: 100%;
                }
            }
        }
    }
}


@media screen and (min-device-width: 576px) and (max-device-width: 768px) {

    #partners {
        .partners-header-container {
            background-image: url('../../public/assets/img/all/partners-header-bg-sm.svg');
        }
    }
}

@media screen and (min-device-width: 768px) and (max-device-width:1025px) {
    #partners {
        .partners-section-one-container {
            .partners-section-one {
                .partners-section-1-artwork {
                    width: 100%;
                }
            }
        }
    }
}