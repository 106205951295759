// Font Variables
$font-path: "../public/assets/fonts/Lexend/static/";
$font-family-lexend: 'Lexend';

@font-face {
  font-family: 'Lexend';
  font-style: 'regular';
  font-weight: 400;
  src: url('../public/assets/fonts/Lexend/static/Lexend-Regular.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: $font-family-lexend !important;
}

.text-purple-gradient {
  background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-green {
  color: #01BC47;
}

.text-highlighter-purple {
  background: linear-gradient(1800deg, rgba(255,0,0,0) 33%, #D3D1FF 10%);
}

.text-highlighter-green {
  background: linear-gradient(1800deg, rgba(255,0,0,0) 33%, #91F5AD 10%);
}

.text-highlighter-orange {
  background: linear-gradient(1800deg, rgba(255,0,0,0) 33%, #FFDAAE 10%);
}

.text-highlighter-blue {
  background: linear-gradient(1800deg, rgba(255,0,0,0) 33%, #6E67DC 10%);
}

.display-linine-block {
  display: inline-block;
}

.hidden {
  display: none;
}