.home-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;

    .learn-more {
        border: none;
        color: #FFFFFF;
        width: 103px;
        height: 32px;
        background: linear-gradient(45.36deg, #01BC47 -4.75%, #21F075 116%);
        border-radius: 4px;
        margin-right: 8px;
    }

    .schedule-demo {
        border: none;
        color: #FFFFFF;
        width: 141px;
        height: 32px;
        background: linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%);
        border-radius: 4px;
    }
}